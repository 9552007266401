import React, { useEffect, useState, useRef } from "react";
import {
  TablePagination,
  makeStyles,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Select,
  MenuItem
} from "@material-ui/core";
import { Edit, Delete, Done, Clear, Search } from "@material-ui/icons";
import axios from "axios";
import Addstaffpopup from "../addstaffpopup";
import Table from "@material-ui/core/Table";
import MaterialTable from "material-table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { ToastSuccess, ToastError } from "../../service/toast/Toast";

function StaffDetailsGride() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const jwttoken = urlParams.get("jwttoken");

  const userEmail = jwttoken
    ? JSON.parse(atob(jwttoken.split(".")[1])).email
    : "";
  const idToken = sessionStorage.getItem("idToken") || jwttoken;
  const [search, setSearch] = useState("");
  const [dataRow, setDataRow] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [open, setOpen] = React.useState(false);
  

  const [specialtyList, setSpecialtiesList] = useState([]);

  // for pagination component
  const [paginationRow, setPaginationRow] = useState(5);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
  React.useEffect(() => {
    //debugger;
    fetchStaffData("");
    fetchSpecialtiesList();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow({});
  };

  const fetchServiceType = () => { };
  const fetchProfilePicture = () => { };

  const addStaff = (data) => {
    if (data) {
      let staffData = dataRow;
      console.log('addStaff :', data)
      staffData.push(data);
      setDataRow(staffData);
    }
    setOpen(false);
  };

  const updateStaffRow = (updatedData) => {
    if (updatedData?.physician_id) {
      const filteredStaff = dataRow.filter(
        (data) => data?.physician_id !== updatedData?.physician_id
      );
      let updatedRow = {
        physician_title: updatedData?.title || "",
        first_name: updatedData?.firstName || "",
        last_name: updatedData?.lastName || "",
        gender: updatedData?.gender || "",
        mci_no: updatedData?.mciNo || "",
        email: updatedData?.email || "",
        contact_number: updatedData?.contactNumber || "",
        specialties: updatedData?.specialities || "",
        practising_med_since: updatedData?.practisingMedSince || "",
        country_code: "",
        physician_id: updatedData?.physician_id || "",
      };
      setDataRow([...filteredStaff, updatedRow]);
      setSelectedRow({});
    }
    setOpen(false);
  };

  const deleteStaffData = async (selectedStaff) => {
    if (selectedStaff?.physician_id) {
      try {
        let deleteStaffConfig = {
          method: "post",
          url: process.env.REACT_APP_BEATS_DELETE_PHYSICIAN,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + idToken,
          },
          data: {
            physicianId: selectedStaff?.physician_id,
          },
        };
        await axios(deleteStaffConfig);
        const filteredStaff = dataRow.filter(
          (data) => data?.physician_id !== selectedStaff?.physician_id
        );
        setDataRow(filteredStaff);
        ToastSuccess("Delete staff successful");
      } catch (err) {
        ToastError("Failed to delete staff");
        console.error(err);
      }
    }
  };

  const editSelectedStaff = (rows) => {
    if (!rows) return;

    let selectedRow = {
      title: rows?.physician_title || "",
      firstName: rows?.first_name || "",
      lastName: rows?.last_name || "",
      gender: rows?.gender || "",
      mciNo: rows?.mci_no || "",
      email: rows?.email || "",
      contactNumber: rows?.contact_number || "",
      specialities: rows?.specialties || "",
      practisingMedSince: rows?.practising_med_since || "",
      countryCode: "",
      physician_id: rows?.physician_id || "",
    };
    setSelectedRow(selectedRow);
    setOpen(true);
  };

  const fetchStaffData = async (sq) => {
    let staffDataConfig = {
      method: "post",
      url: process.env.REACT_APP_BEATS_GET_PHYSICIANS,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
      data: {
        q: sq
      }
    };
    try {
      let response = await axios(staffDataConfig);
      if (response?.data?.length) {
        //  console.log("fetch staff : ",response?.data);
        setDataRow(response?.data || []);               
      }
    } catch (e) {
      console.log("failed to fetch Physician Records");
    }
  };

  const fetchSpecialtiesList = async () => {
    let specialtiesConfigure = {
      method: "get",
      url: process.env.REACT_APP_BEATS_GET_SPECIALTIES_API,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + sessionStorage.getItem("idToken"),
      },
    };
    try {
      let specialties = await axios(specialtiesConfigure);
      //ex : {data: [{}]}
      let specialtiesData = [];
      if (specialties.data.length > 0) {
        specialties.data.map((item, index) => {
          if (item != null) {
            let specialtyObj = {
              value: item.specialty_name,
              label: item.specialty_name,
              visit_reason: item.visit_reason,
            };
            specialtiesData.push(specialtyObj);
          }
        });
      }
      setSpecialtiesList(specialtiesData);
    } catch (error) {
      console.log("my profile specialty data not load", error);
    }
  };

  const CustomPagination = () => {
    const pages = [5, 10, 20, 30, 40];
    const MenuItemList = pages.map((item, index) => (
      <MenuItem value={item}>{item}</MenuItem>
    ));
    const handleChange = (event) => {
      setPaginationRow(event.target.value);
    };
    return (
      <FormControl>
        <InputLabel id="demo-simple-select-label">row</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={paginationRow}
          label="row"
          onChange={handleChange}
        >
          {MenuItemList}
        </Select>
      </FormControl>
    );
  };

  const Pagination = () => {
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));  
      setPage(0);
    };
    return (
      <TablePagination
        component="div"
        count={dataRow.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    );
  };

  const getTableData = () => {
    // console.log("DataRow : ", dataRow);
    //sort by physician_id : 1 < 2 < 3 < 4 ....
    var arrPhysicianId = [];
    var tmpDataRow = [];
    //console.log('DataRow :', dataRow);
    var startValue=((page+1)*rowsPerPage-rowsPerPage); 
    var endValue=(page+1)*rowsPerPage;
    var data=dataRow.slice(startValue,endValue>dataRow.length?dataRow.length:endValue);
    data?.map((item, index) => {
      var num = parseInt(item?.physician_id)
      arrPhysicianId.push(num);
      //console.log(item?.physician_id);
    });
    // var reverseSortArrPhysicianId = arrPhysicianId.sort();//.reverse();
    //console.log('reverseSortArrPhysicianId :', reverseSortArrPhysicianId);
    //console.log("arrPhysicianId : ", arrPhysicianId);
    var reverseSortArrPhysicianId = [];
    for (let i = 0; i <= arrPhysicianId.length; i++) {
      reverseSortArrPhysicianId.push(arrPhysicianId[arrPhysicianId.length - i]);
    }
    //console.log('reverseSortArrPhysicianId :', reverseSortArrPhysicianId);

    reverseSortArrPhysicianId.map((item, index) => {
      data.map((itm, ind) => {
        if (item === itm?.physician_id) {
          tmpDataRow.push(itm);
        }
      });
    });
    //console.log("tmpDataRow :", tmpDataRow);

    const tableRows = tmpDataRow?.map((rows) => (
      <TableRow style={{ position: "relative" }}>
        <TableCell>{rows?.physician_title}</TableCell>
        <TableCell>{rows?.first_name}</TableCell>
        <TableCell>{rows?.last_name}</TableCell>
        <TableCell>{rows?.gender}</TableCell>
        <TableCell>{rows?.mci_no}</TableCell>
        <TableCell>{rows?.email}</TableCell>
        <TableCell>{rows?.contact_number}</TableCell>
        <TableCell>{rows?.specialties}</TableCell>
        <TableCell>{rows?.practising_med_since}</TableCell>
        <TableCell className="tbl-btn-wdith">
          <Edit
            onClick={() => {
              editSelectedStaff(rows);
            }}
          />
          <Delete
            onClick={() => {
              deleteStaffData(rows);
            }}
          />
        </TableCell>
      </TableRow>
    ));
    return tableRows;
  };

  return (
    <Grid container spacing={6}>
      {/**Header row search.add staff button*/}
      <Grid item xs={12} spacing={6}>
        <Grid container>
          <Grid items xs={6}>
            <h6> Staff Details (to assign to patients)</h6>
          </Grid>
          <Grid items xs={3}>
            <Button
              size="large"
              variant="contained"
              style={{ backgroundColor: "red" }}
              onClick={handleClickOpen}
            >
              {`Add staff`}
            </Button>
            {open && (
              <Addstaffpopup
                selectedRow={selectedRow}
                addStaff={addStaff}
                updateStaffData={updateStaffRow}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                open={open}
              />
            )}
            {/*<Button
                            size="large"
                            variant="contained"
                            style={{ backgroundColor: "red" }}
                            onClick={() => { alert("click") }}
                        >
                            <h style={{ fontSize: "14px", color: "white", }}>Add Staff</h>
              </Button>*/}
          </Grid>
          <Grid items xs={3}>
            <FormControl variant="standard">
              <InputLabel htmlFor="input-with-icon-adornment"></InputLabel>
              <Input
                onChange={(e) => {
                  setSearch(e.target.value);
                  console.log(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key == "Enter") {
                    fetchStaffData(e.target.value);
                  }
                }}
                value={search}
                id="input-with-icon-adornment"
                placeholder="Search"
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="start">
                    <Clear
                      onClick={() => {
                        setSearch("");
                        fetchStaffData("");
                      }}
                    />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} className="custom-table">
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell> License No. / NPI </TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Cell Number</TableCell>
                <TableCell> Specialty </TableCell>
                <TableCell>Practicing Since</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataRow.length ? getTableData() : <>{"No Records Found."}</>}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid
        item
        xs={12}
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Pagination />
      </Grid>
    </Grid>
  );
}
export default StaffDetailsGride;
