import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Datepickermod from "./datepicker";
import Divider from "@material-ui/core/Divider";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { createStyles, Theme } from "@material-ui/core/styles";
//import { Specialties_List_fetch, Title_List, Gender_List } from './Util/GetData';
import { CellPhoneNumberFormating, EmailValidate } from "./Util/Utils";

import { useForm, Controller } from "react-hook-form";

import { ToastError, ToastSuccess } from "../service/toast/Toast";

const EMAIL_REGEX_VALIDATION = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const staffModel = () => {
  return {
    title: "",
    firstName: "",
    lastName: "",
    gender: "",
    mciNo: "",
    email: "",
    contactNumber: "",
    specialities: "",
    practisingMedSince: "",
    countryCode: "",
  };
};

export default function Addstaffpopup(props) {
  const [staffTitle, setStaffTitle] = React.useState("");
  const [since, setSince] = React.useState('');
  const { selectedRow, open, handleClose } = props;

  const modalTitle = selectedRow?.physician_id ? "Update" : "Add";

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const jwttoken = urlParams.get("jwttoken");

  const userEmail = jwttoken
    ? JSON.parse(atob(jwttoken.split(".")[1])).email
    : "";
  const idToken = sessionStorage.getItem("idToken") || jwttoken;
  const drawerWidth = 240;
  const [autoFocusing, setAutoFocusing] = React.useState({
    firstName: false,
    lastName: false,
    licenseNo: false,
    email: false,
    cellNumber: false,
    practicingSince: false,
  });

  React.useEffect(() => {
    fetchSpecialtiesList();
  }, []);

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors, isDirty },
    control,
    reset,
    setError,
  } = useForm({ defaultValues: staffModel() });

  const isRowSelected = () => {
    if (selectedRow?.physician_id) {
      reset(selectedRow);
    } else reset(staffModel());
  };

  const [specialtiesListV, setSpecialtiesListV] = React.useState([]);

  const [addStaffUpdateList, setAddStaffUpdateList] = React.useState({
    title: "",
    firstName: "",
    lastName: "",
    gender: "",
    licenseNo: "",
    email: "",
    cellNumber: "",
    specialty: "",
    practicingSince: "",
  });

  const getValuesFromFeilds = (key, val) => {
    console.log("key : ", key, " value : ", val);
    let dataObj = { ...addStaffUpdateList };
    var value = "";
    dataObj[key] = val;
    setAddStaffUpdateList(dataObj);
  };

  const saveAddStaffFunction = () => {
    console.log(addStaffUpdateList);
  };

  const closeModal = () => {
    handleClose(false);
  };

  const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
      root: { "label + &": { marginTop: theme.spacing(3) } },
      input: {},
    })
  )(InputBase);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
      /**minWidth:120 */
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  React.useEffect(() => {
    isRowSelected();
  }, [open]);

  const MenuItemListGender = ["Male", "Female"].map((item, index) => (
    <MenuItem value={item}>{item}</MenuItem>
  ));
  const MenuItemListTitle = ["Dr.", "Prof.", "Mr.", "Ms.", "Mrs."].map(
    (item, index) => <MenuItem value={item}>{item}</MenuItem>
  );

  const fetchSpecialtiesList = async () => {
    let specialtiesConfigure = {
      method: "get",
      url: process.env.REACT_APP_BEATS_GET_SPECIALTIES_API,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
    };
    try {
      let specialties = await axios(specialtiesConfigure);
      let specialtiesData = [];
      let specialtiesList = [];
      let tempSpecialtiesList = [];
      for (var i = 0; i < specialties.data.length; i++) {
        if (
          tempSpecialtiesList.indexOf(specialties.data[i].specialty_name) > -1
        ) {
          continue;
        } else {
          let specialtyObj = {
            value: specialties.data[i].specialty_name,
            label: specialties.data[i].specialty_name,
          };
          specialtiesList.push(specialtyObj);
          tempSpecialtiesList.push(specialties.data[i].specialty_name);
        }
        let obj = {
          value: specialties.data[i].specialty_name,
          label: specialties.data[i].specialty_name,

          visit_reason: specialties.data[i].visit_reason,
          specialty_name: specialties.data[i].specialty_name,
        };
        specialtiesData.push(obj);
      }
      setSpecialtiesListV(specialtiesData);
      console.log(specialtiesListV);
    } catch (error) {
      // setSpecialtiesListV([{ value: "Data Not load", label: "Error ! Data Not load 848" }]);
      console.log("my profile specialty data not load", error);
    }
  };

  const MenuItemListSpecialty = specialtiesListV.map((val) => (
    <MenuItem value={val.value}> {val.label} </MenuItem>
  ));

  /*const MenuItemListSpecialty = [
    { value: "a" },
    { value: "b" },
    { value: "c" },
  ].map((item, index) => <MenuItem value={item.value}>{item.value}</MenuItem>);*/

  const handleFormSubmit = async (data) => {
    //  debugger;
    console.log("data gggggg", data);

    try {
      if (selectedRow?.physician_id) {
        //Update operation if staffId is present.
        let updatedRec = data;
        updatedRec["physicianId"] = selectedRow?.physician_id;
        let updateStaffConfig = {
          method: "post",
          url: process.env.REACT_APP_BEATS_UPDATE_PHYSICIAN,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + idToken,
          },
          data: data,
        };
        let updatedSuccessData = await axios(updateStaffConfig);
        console.log("updatedSuccessResponse", updatedSuccessData);
        if (typeof props?.updateStaffData === "function") {
          const { updateStaffData } = props;
          updateStaffData(updatedRec);
        }
        console.log("updatedSuccessResponse", updatedSuccessData);
      } else {
        // Insert operation if staffId is absent.
        let addStaffConfig = {
          method: "post",
          url: process.env.REACT_APP_BEATS_ADD_PHYSICIAN,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + idToken,
          },
          data: data,
        };
        let newStaff = await axios(addStaffConfig);
        console.log("insertedResponse", newStaff);
        const newRecord = newStaff?.data?.newRecord?.[0]?.[0];
        if (typeof props?.addStaff === "function") {
          const { addStaff } = props;
          addStaff(newRecord);
        }
      }
      ToastSuccess(`${modalTitle} staff successful`);
    } catch (err) {
      ToastError(`Failed to ${modalTitle} staff`);
      console.log(err);
    }
    console.log(data);
  };

  return (
    <div>
      {/*<Button
        size="large"
        variant="contained"
        style={{ backgroundColor: "red" }}
        onClick={handleClickOpen}
      >
        {`${modalTitle} staff`}
      </Button>*/}
      <Dialog
        open={open}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container spacing={3} justify="center" className="addstaffpopup">
              <Grid item xs={12}>
                <h2 className="title1">{`${modalTitle} staff`}</h2>
                <Grid className="detail-list" container spacing={2}>
                  <Divider
                    variant="middle"
                    className=" dividercls width100p mar0"
                  />
                  <Grid item xs={12}></Grid>
                  <Grid item xs={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} className="details-label">
                        Title
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className="details-label"
                        style={{ color: "#828282" }}
                      ></Grid>
                      <Grid
                        item
                        xs={12}
                        className="details-value datetop0 timelist"
                      >
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            name="title"
                            rules={{
                              required: {
                                value: true,
                                message: "Title is Required.",
                              },
                            }}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                labelId="start-time"
                                id="start-time"
                                className="selectborder"
                                onChange={(e) => {
                                  onChange(e);
                                  setStaffTitle(e.target.value);
                                  console.log("e.target.value = ", staffTitle);
                                }}
                                value={value}
                              >

                                {MenuItemListTitle}

                              </Select>
                            )}
                          />
                          <span className="errormessage">  {errors?.title?.message || ""} </span>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={5}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} className="details-label">
                        First Name
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className="details-label"
                        style={{ color: "#828282" }}
                      ></Grid>
                      <Grid
                        item
                        xs={12}
                        className="details-value datetop0 timelist"
                      >
                        <Controller
                          control={control}
                          name="firstName"
                          defaultValue=""
                          rules={{
                            required: {
                              value: true,
                              message: "First Name is required.",
                            },
                          }}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <input
                              name="firstName"
                              type="text"
                              className="primary-input   width100p"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}

                              label="First Name"
                              error={errors?.firstName}
                            />
                          )}
                        />
                        <span className="errormessage">   {errors?.firstName?.message || ""} </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={4}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} className="details-label">
                        Last Name
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className="details-label"
                        style={{ color: "#828282" }}
                      ></Grid>
                      <Grid
                        item
                        xs={12}
                        className="details-value datetop0 timelist"
                      >
                        <Controller
                          control={control}
                          name="lastName"
                          rules={{
                            required: {
                              value: true,
                              message: "Last Name is Required.",
                            },
                          }}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <input
                              name="lastName"
                              type="text"
                              className="primary-input   width100p"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}

                              label="Last Name"
                            />
                          )}
                        />
                        <span className="errormessage">  {errors?.lastName?.message || ""} </span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} className="details-label">
                        Gender
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className="details-label"
                        style={{ color: "#828282" }}
                      ></Grid>
                      <Grid item xs={12} className="details-value datetop0">
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            name="gender"
                            rules={{
                              required: {
                                value: true,
                                message: "Gender is Required.",
                              },
                            }}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                labelId="end-time"
                                id="end-time"
                                className="selectborder"
                                onChange={onChange}
                                value={value}
                              >
                                {MenuItemListGender}
                              </Select>
                            )}
                          />
                        </FormControl>
                        <span className="errormessage">   {errors?.gender?.message || ""} </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={8}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} className="details-label">
                        License No. / NPI
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className="details-label"
                        style={{ color: "#828282" }}
                      ></Grid>
                      <Grid item xs={12} className="details-value datetop0">
                        <Controller
                          control={control}
                          name="mciNo"
                          rules={{
                            required: {
                              value: staffTitle !== "Dr." ? false : true,
                              message: "License No/Npi is Required.",
                            },
                          }}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <input
                              name="mciNo"
                              type="text"
                              className="primary-input   width100p"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}

                              label="License No"
                            />
                          )}
                        />
                        <span className="errormessage">  {errors?.mciNo?.message || ""} </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} className="details-label">
                        Email
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className="details-label"
                        style={{ color: "#828282" }}
                      ></Grid>
                      <Grid item xs={12} className="details-value datetop0">
                        <Controller
                          control={control}
                          name="email"
                          rules={{
                            pattern: {
                              value: EMAIL_REGEX_VALIDATION,
                              message: "Please enter valid email",
                            },
                            required: {
                              value: true,
                              message: "Email is Required.",
                            },
                          }}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <input
                              name="email"
                              type="text"
                              className="primary-input   width100p"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}

                              label="Email"
                            />
                          )}
                        />
                        <span className="errormessage">  {errors?.email?.message || ""} </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} className="details-label">
                        Cell Number
                      </Grid>

                      <Grid item xs={12} className="details-value">
                        <Controller
                          control={control}
                          name="contactNumber"
                          rules={{
                            required: {
                              value: true,
                              message: "Cell Number is Required.",
                            },
                            validate: (val) =>
                              val.length === 12 ||
                              "Please enter a valid phone number",
                          }}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <input
                              name="contactNumber"
                              type="text"
                              className="primary-input   width100p"
                              value={value}
                              onChange={(e) => {
                                let value = CellPhoneNumberFormating(
                                  e.target.value
                                );
                                onChange(value);
                              }}
                              onBlur={onBlur}
                              maxlength="10"
                              label="Cell Number"
                            />
                          )}
                        />
                        <span className="errormessage"> {errors?.contactNumber?.message || ""} </span>
                        {/*<BootstrapInput
                          className="primary-input   width100p"
                          autoFocus={autoFocusing.cellNumber}
                          value={addStaffUpdateList.cellNumber}
                          onChange={(e) => {
                            setAutoFocusing({
                              firstName: false,
                              lastName: false,
                              licenseNo: false,
                              email: false,
                              cellNumber: true,
                              practicingSince: false,
                            });

                            if (e.target.value.length <= 10) {
                              getValuesFromFeilds(
                                "cellNumber",
                                CellPhoneNumberFormating(e.target.value)
                              );
                            }
                          }}
                        />*/}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={7}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className="details-label">
                        Specialty
                      </Grid>

                      <Grid item xs={12} className="details-value">
                        <FormControl fullWidth>
                          <Controller
                            control={control}
                            name="specialities"
                            rules={{
                              required: {
                                value: true,
                                message: "Specialties is Required.",
                              },
                            }}
                            render={({
                              field: { onChange, value, onBlur },
                            }) => (
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                className="selectborder"
                                name="specialities"
                                onChange={onChange}
                                value={value}
                              >
                                {MenuItemListSpecialty}
                              </Select>
                            )}
                          />
                        </FormControl>
                        <span className="errormessage">{errors?.specialities?.message || ""} </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={5}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className="details-label">
                        Practicing Since
                      </Grid>
                      <Grid item xs={12} className="details-value">
                        <Controller
                          control={control}
                          name="practisingMedSince"
                          rules={{
                            required: {
                              value: true,
                              message: (new Date().getFullYear() + 1) > since ? '' : "Practicing Med Since is required.",
                            },
                          }}
                          render={({ field: { onChange, value, onBlur } }) => (
                            <input
                              name="practisingMedSince"
                              //type="tel"
                              type='number'
                              //type="text"
                              className="primary-input   width100p"
                              value={(0 < value) ? (new Date().getFullYear() + 1) > value ? value : '' : null}
                              onChange={(e) => { setSince(e.target.value); onChange(e); }}
                              onBlur={onBlur}
                              //maxLength={4}
                              inputmode="numeric"
                            //min={1950}
                            //max={new Date().getFullYear() + 1}
                            style={{ }}
                            />
                          )}
                        />
                        <span className="errormessage"> {errors?.practisingMedSince?.message || ""} </span>

                        {/*<BootstrapInput
                          className="primary-input   width100p"
                          autoFocus={autoFocusing.practicingSince}
                          value={addStaffUpdateList.practicingSince}
                          onChange={(e) => {
                            setAutoFocusing({
                              firstName: false,
                              lastName: false,
                              licenseNo: false,
                              email: false,
                              cellNumber: false,
                              practicingSince: true,
                            });
                            getValuesFromFeilds(
                              "practicingSince",
                              e.target.value
                            );
                          }}
                        />*/}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} className="mt20">
                    <Grid container spacing={1}>
                      <Grid item xs={12} className="details-label mb20">
                        <Button
                          variant="contained"
                          className="cancelbtn mr30"
                          onClick={closeModal}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          className="btn-primary addstaffbtn"
                          disabled={!isDirty}
                        // onClick={saveAddStaffFunction}
                        >
                          {`${modalTitle} Staff`}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
