import React, { useEffect } from "react";
import clsx from "clsx";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import axios from "axios";
import LeftsideMenu from "./LeftsideMenu";
import "react-toastify/dist/ReactToastify.css";
import { ToastError, ToastSuccess } from "../service/toast/Toast";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import { createStyles, Theme } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import CloudUpload from "@material-ui/icons/CloudUpload";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import logodb from "../img/Beats-health-logo.png";
import PropTypes from "prop-types";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Widget from "./doublebox";
import ImageUploader from "react-images-upload";
import Select from "react-select";
import Divider from "@material-ui/core/Divider";

import MaterialTable from "material-table";
import { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeUpdate from "./StripeUpdate";
import StateManager from "react-select";

import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MUIselect from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@mui/material/TextField";
import StaffDetailsGride from "../components/Util/StaffDetailsGride";
import Addstaffpopup from "./addstaffpopup";
import { useHistory } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CreateSharpIcon from "@material-ui/icons/CreateSharp";


const tableIcons = {
  Add: forwardRef((props, ref) => (
    <>
      {/*<AddBox  {...props} ref={ref} style={{ color: "red" }} />*/}
      <Button
        variant="contained"
        className="btn-primary addstaff"
        style={{ margin: 0, padding: 0 }}
      >
        <h style={{ fontSize: "14px", color: "white" }}>Add Staff</h>
      </Button>
    </>
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const drawerWidth = 240;
const stripePromise = loadStripe(
  "pk_test_51Ij5ayC2V2ajAK3d3juQBP9WJa8iBbj28QCpVyvCFfK2GNYNVWZY3ykXnBtW63PZcr1MTfQApRXgArNU3jSpI6ac004zNPrvoA"
);

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
    borderBottom: "1px dotted pink",
    padding: 20,
  }),
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  customWidth: { fontSize: "1px" },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    /**minWidth:120 */
    minWidth: 120,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {},

    paper: {
      padding: theme.spacing(0),
      color: theme.palette.text.secondary,
    },
  })
)(InputBase);

const IndianRegionScreenStatus = {
  MyDetail: "My_Detail",
  StaffDetail: "Staff_Detail",
  MySubscription: "My_Subscriptions",
  Region: "INDIA",
};

export default function MiniDrawer() {
  const classes = useStyles();
  const { id } = useParams();
  const [open] = React.useState(false);
  const [inputType, setInputType] = React.useState('number');
  const [value, setValue] = React.useState(0);
  const [providerName, setProviderName] = React.useState("");
  const [registerNo, setRegisterNo] = React.useState("");
  const [registeredContactNumber, setregisteredContactNumber] = React.useState("");
  const [registeredContactNumberFormat, setRegisteredContactNumberFormat] = React.useState('');
  const [contactPhoneNumber, setContactPhoneNumber] = React.useState(0);

  const [add1, setAdd1] = React.useState("");
  const [add2, setAdd2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [countryCode, setcountryCode] = React.useState("");

  const history = useHistory();
  const [tabState, setTabState] = React.useState("view");
  const [currentPass, setCurrentPass] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPass, setConfirmPass] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  const [typeOfPractice, setTypeofPractice] = React.useState("");
  const [typeOfService, setTypeofService] = React.useState("");
  const [pictures, setProfilePicture] = React.useState({ image: "", url: "" });
  const [addressLine1, setAddressLine1] = React.useState("");
  const [addressLine2, setAddressLine2] = React.useState("");
  const [cityName, setCityName] = React.useState("");
  const [stateName, setStateName] = React.useState("");
  const [countryName, setCountryName] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [viewRecord, setViewRecord] = React.useState([]);
  const [zipCode, setZipCode] = React.useState("");
  const [taxonomyDesc, setTaxonomyDesc] = React.useState("");
  const [taxonomyCode, setTaxonomyCode] = React.useState("");
  const [taxonomyGroup, setTaxonomyGroup] = React.useState("");
  const [taxonomyState, setTaxonomyState] = React.useState("");
  const [taxonomyLicense, setTaxonomyLicense] = React.useState("");
  const [contactNumber, setContactNumber] = React.useState("");
  const uploadInputRef = React.useRef(null);
  const [isDateChecked, setisDateChecked] = React.useState(false);
  const [isOnDateServiceChecked, setOnDateServiceChecked] =
    React.useState(false);
  const [noOfDays, setNumbOfDays] = React.useState(0);
  const [npiDetails, setNpiDetails] = React.useState("");
  const [hasLocalData, setHasLocalData] = React.useState(false);
  const [screenStatus, setScreenStatus] = React.useState("");

  const [nameError, setNameError] = React.useState({
    error: false,
    label: "",
    helperText: "",
    validateInput: false,
  });

  //for specialty ui drop down
  const [specialtiesListV, setSpecialtiesListV] = React.useState([]);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const jwttoken = urlParams.get("jwttoken");
  const accessToken = urlParams.get("accesstoken");
  // console.log("token", accessToken);
  const usermail = jwttoken
    ? JSON.parse(atob(jwttoken.split(".")[1])).email
    : "";

  const fetchedPassword = accessToken || "Beats@2021";

  const idToken = sessionStorage.getItem("idToken")
    ? sessionStorage.getItem("idToken")
    : jwttoken;

  const setregisteredContactNumberFormatted = (fieldName, val) => {
    const regExNumberOnly = /^\d{0,10}$/;
    function isEn(str) {
      return /^[a-zA-Z]+$/.test(str);
    }
    function isN(str) {
      return regExNumberOnly.test(str);
    }
    if (fieldName === 'registeredContactNumber') {
      if (isN(val)) {
        if (val.length < 10) {
          //var ft = val.substring(0, 3) + '-' + val.substring(3, 6) + '-' + val.substring(6, 10);
          setRegisteredContactNumberFormat(val);
        }

        if (val.length === 10) {
          var ft = val.substring(0, 3) + '-' + val.substring(3, 6) + '-' + val.substring(6, 10);
          setRegisteredContactNumberFormat(ft);
        }
      } else {
        if (!isEn(val) && !isN(val) && val.length < 12) { setRegisteredContactNumberFormat(val); }
      }



    }
  }
  const getCurrentUserattrib = () => {
    if (usermail) {
      let data = JSON.stringify({ username: usermail });
      let config = {
        method: "post",
        url: process.env.REACT_APP_BEATS_USER_SIGN_IN_API,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      setLoading(true);
      axios(config)
        .then(function (response) {
          //debugger;
          sessionStorage.setItem("accessToken", response.data.accessToken);
          sessionStorage.setItem("idToken", response.data.idToken);
          sessionStorage.setItem("refreshToken", response.data.refreshToken);
          let data = response.data.attributes;
          let newArray = [];
          data.forEach((val) => {
            let namekey = Object.keys(val)[0];
            let valuekey = Object.keys(val)[1];
            let name = val[namekey];
            let value = val[valuekey];
            newArray.push({
              name: name,
              value: value,
            });
          });
          sessionStorage.setItem("attributes", JSON.stringify(newArray));
          //sessionStorage.setItem("attributes", JSON.stringify(response.data.attributes));
          localStorage.setItem("accessToken", response.data.accessToken);
          localStorage.setItem("idToken", response.data.idToken);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          localStorage.setItem("attributes", JSON.stringify(newArray));
          console.log("accesstoken in mydetail signin", fetchedPassword);
          setCurrentPass(fetchedPassword);
          //localStorage.setItem("attributes", JSON.stringify(response.data.attributes));
          //screenStatus();
          // let url = "/Dashboard";
          // console.log("data", data);
          // history.push(url);
        })
        .catch(function (error) {
          setLoading(false);
          history.push("/SignIn");
        });
    } else {
      history.push("/SignIn");
    }
  };

  const attribs = () => {
    return localStorage.getItem("attributes")
      ? JSON.parse(localStorage.getItem("attributes"))
      : [];
  };

  const attribVal = (attribName) => {
    let att = attribs();
    let ky = att.find((x) => x.name === attribName);
    return ky && ky.value ? ky.value : "";
  };

  const attribFilter = (attribName) => {
    let att = attribs();
    let retval = att.filter((val) => val.name !== attribName);
    return retval ? retval : [];
  };

  const setProviderScreenStatus = (status) => {
    if (!status) setValue(0);
    else if (status === IndianRegionScreenStatus.MyDetail) setValue(1);
    else if (status === IndianRegionScreenStatus.StaffDetail) setValue(2);
    setScreenStatus(status);
  };

  const fetchScreenStatus = async () => {
    try {
      let config = {
        method: "post",
        url: process.env.REACT_APP_BEATS_FETCH_SCREEN_STATUS,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      };
      let data = await axios(config);
      let screenStatusText = data["data"][0].screen_completed_status;
      setProviderScreenStatus(screenStatusText);
      setLoading(false);
    } catch (err) {
      if (err?.response?.status === 404) {
        setProviderScreenStatus("");
      }
      setLoading(false);
      console.log(err);
    }
  };

  React.useEffect(() => {
    if (jwttoken) getCurrentUserattrib();

    fetchServiceType();
    fetchProfilePicture();
    fetchSpecialtiesList();
    //  if (process.env.REACT_APP_REGION === "INDIA" && jwttoken) {
    fetchScreenStatus();
    // }
  }, []);

  /****function to fetch Provider Profile Picture****/
  const fetchProfilePicture = async () => {
    let fileUrl = "";

    try {
      fileUrl = attribVal("url");
    } catch (error) {
      console.log("url missing in profile");
    }
    if (fileUrl === "" || fileUrl === undefined || fileUrl === null) {
      return false;
    }
    let profilePictureConfig = {
      method: "post",
      url: process.env.REACT_APP_BEATS_FETCH_PROFILE_PICTURE,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
      data: {
        key: fileUrl,
      },
    };

    let specialtiesConfig = {
      method: "get",
      url: process.env.REACT_APP_BEATS_GET_SPECIALTIES_API,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
    };

    try {
      let profileData = await axios(profilePictureConfig);
      if (profileData !== undefined) {
        setProfilePicture({ image: "", url: profileData.data });
      }
    } catch (e) {
      console.log("failed to fetch Provider Image");
    }
  };

  const fetchServiceType = async () => {
    // debugger
    let serviceConfig = {
      method: "get",
      url: process.env.REACT_APP_BEATS_LIST_SERVICE_TYPE_API,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
    };

    let providerConfig = {
      method: "post",
      url: process.env.REACT_APP_BEATS_FETCH_PROVIDER_DETAILS,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
    };

    const attData = attribs();

    setLoading(true);
    const typeofPracticeAttr = attribVal("custom:TypeOfPractice");
    let typeofServiceAttr = "30";

    try {
      typeofServiceAttr = attribVal("custom:TypeOfService");
    } catch (error) {
      console.log("Default service id missing in profile");
    }

    try {
      let service = await axios(serviceConfig);
      let serviceType = "";
      for (var j = 0; j < service.data.length; j++) {
        if (service.data[j].service_type_Id === parseInt(typeofServiceAttr)) {
          serviceType = service.data[j].service_name;
          break;
        }
      }
      setTypeofService(serviceType);
      setTypeofPractice(typeofPracticeAttr);

      let provider = await axios(providerConfig);
      console.log("Provider call data: ", provider);
      if (provider.data.length > 0) {
        setAddressLine1(provider.data[0].address_line1);
        setAddressLine2(provider.data[0].address_line2);
        setCityName(provider.data[0].city);
        setStateName(provider.data[0].state);
        setCountryName(provider.data[0].country);
        setZipCode(provider.data[0].zip);
        setTaxonomyDesc(provider.data[0].primary_taxonomy_desc);
        setTaxonomyCode(provider.data[0].primary_taxonomy_code);
        setTaxonomyGroup(provider.data[0].primary_taxonomy_group);
        setTaxonomyLicense(provider.data[0].primary_taxonomy_license);
        setTaxonomyState(provider.data[0].primary_taxonomy_state);
        setContactNumber(provider.data[0].contact_number);
        setcountryCode(provider.data[0].country_code);
        setregisteredContactNumber(provider.data[0].registeredContactNumber);

        setisDateChecked(provider.data[0].onDateService);
        setOnDateServiceChecked(provider.data[0].daysBeforeDateOfService);
        setNumbOfDays(provider.data[0].daysbefore);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    ////debugger;
  };

  const showLoadMask = (status) => {
    setLoading(status);
  };

  const saveIndianRegionDetails = () => {
    console.log("saveIndianRegionDetails");
    const attData = attribs();
    const nameOfHospital = providerName || attribVal("custom:Organization");
    const npi = npiDetails || attribVal("custom:NPI");
    const typeOfPractice = spectl || attribVal("custom:TypeOfPractice");

    let operationType = screenStatus ? "update" : "insert";
    if (!add1) {
      ToastError("Address Line 1 is required");
      return false;
    }

    if (!city) {
      ToastError("City is required");
      return false;
    }

    if (!state) {
      ToastError("State is required");
      return false;
    }
    if (!country) {
      ToastError("Country is required");
      return false;
    }
    if (!zip) {
      ToastError("Pin Code is required");
      return false;
    }

    if (!currentPass) {
      ToastError("Current Password is required");
      return false;
    }
    if (!newPassword) {
      ToastError("New Password is required");
      return false;
    }
    if (!confirmPass) {
      ToastError("Confirm Password is required");
      return false;
    }

    if (!providerName) {
      ToastError("Name of Provider/Practice is required");
      return false;
    }

    if (newPassword !== confirmPass) {
      ToastError("New Password and Confirm Password are not Matching");
      return false;
    }

    const myDetailScreenStatus =
      screenStatus === "COMPLETE"
        ? "COMPLETE"
        : IndianRegionScreenStatus.MyDetail;

    let data = JSON.stringify({
      address1: add1,
      address2: add2,
      city: city,
      state: state,
      postalCode: zip,
      password: jwttoken ? "" : currentPass,
      newPassword: newPassword,
      Organization: providerName,
      TypeOfPractice: typeOfPractice,
      contactNumber: contactNumber,
      countryName: country,
      regNo: registerNo,
      screenStatus: myDetailScreenStatus,
      countryCode: countryCode,
    });

    console.log(data);
    console.log("type", operationType);
    let config = {
      method: "post",
      url: process.env.REACT_APP_BEATS_USER_UPDATE_API,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "is-update": operationType,
      },
      data: data,
    };
    showLoadMask(true);
    axios(config)
      .then(function (response) {
        showLoadMask(false);
        ToastSuccess("Successfully Updated.");
        if (screenStatus !== "COMPLETE") {
          setProviderScreenStatus(IndianRegionScreenStatus.MyDetail);
        }
      })
      .catch(function (error) {
        showLoadMask(false);
        ToastError(error?.response?.data?.error
          || 'Failed to update provider details');
        console.log(error);
      });
  };
  const updateIndianRegionDetails = () => {
    console.log("updateIndianRegionDetails");
    const attData = attribs();
    const nameOfHospital = providerName || attribVal("custom:Organization");
    const npi = npiDetails || attribVal("custom:NPI");
    const typeOfPractice = spectl || attribVal("custom:TypeOfPractice");

    let operationType = screenStatus ? "update" : "insert";
    if (!add1) {
      ToastError("Address Line 1 is required");
      return false;
    }

    if (!city) {
      ToastError("City is required");
      return false;
    }

    if (!state) {
      ToastError("State is required");
      return false;
    }
    if (!country) {
      ToastError("Country is required");
      return false;
    }
    if (!zip) {
      ToastError("Pin Code is required");
      return false;
    }
    /* if (!currentPass) {
      ToastError("Current Password is required");
      return false;
    } */

    if (!providerName) {
      ToastError("Name of Provider/Practice is required");
      return false;
    }



    const myDetailScreenStatus =
      screenStatus === "COMPLETE"
        ? "COMPLETE"
        : IndianRegionScreenStatus.MyDetail;

    let data = JSON.stringify({
      address1: add1,
      address2: add2,
      city: city,
      state: state,
      postalCode: zip,
      //password: jwttoken ? "" : currentPass,
      password: atob(localStorage.getItem("providerKey")),
      //newPassword: currentPass,
      Organization: providerName,
      TypeOfPractice: typeOfPractice,
      contactNumber: contactNumber,
      registeredContactNumber: registeredContactNumber,
      countryName: country,
      regNo: registerNo,
      screenStatus: myDetailScreenStatus,
      countryCode: countryCode,
    });

    console.log(data);
    console.log("type", operationType);
    let config = {
      method: "post",
      url: process.env.REACT_APP_BEATS_USER_UPDATE_API,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "is-update": operationType,
      },
      data: data,
    };
    showLoadMask(true);
    axios(config)
      .then(function (response) {
        showLoadMask(false);
        ToastSuccess("Successfully Updated.");
        if (screenStatus !== "COMPLETE") {
          setProviderScreenStatus(IndianRegionScreenStatus.MyDetail);
        }
        setTabState("view");
      })
      .catch(function (error) {
        showLoadMask(false);
        ToastError(error?.response?.data?.error
          || 'Failed to update provider details');
        console.log(error);
      });
  };
  const [smsBeforeApt, setSmsBeforeApt] = useState("");
  const [reminderBeforeApt, setReminderBeforeApt] = useState("");

  const handleSelectChange = (selectedOption) => {
    setSmsBeforeApt(selectedOption);
  };

  const handleReminderChange = (selectedOption) => {
    setReminderBeforeApt(selectedOption);
    console.log(`Option selected:`, selectedOption.value);
  };

  const fetchIndianRegionDetails = async () => {
    let url = process.env.REACT_APP_BEATS_FETCH_PROVIDER_DETAILS;
    let providerConfig = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
    };

    //const attData = attribs();
    const nameOfHospital = attribVal("custom:Organization") || providerName;
    // const npi = attribVal("custom:NPI") || npiDetails;
    const typeOfPractice = attribVal("custom:TypeOfPractice") || spectl;

    setLoading(true);
    //const typeofPracticeAttr = attribVal("custom:TypeOfPractice");
    //let typeofServiceAttr = "30";

    /*try {
      typeofServiceAttr = attribVal("custom:TypeOfService");
    } catch (error) {
      console.log("Default service id missing in profile");
    }*/

    try {
      /*let service = await axios(serviceConfig);
      let serviceType = "";
      for (var j = 0; j < service.data.length; j++) {
        if (service.data[j].service_type_Id === parseInt(typeofServiceAttr)) {
          serviceType = service.data[j].service_name;
          break;
        }
      }*/
      //setTypeofService(serviceType);
      //setTypeofPractice(typeofPracticeAttr);
      let provider = await axios(providerConfig);
      console.log("Provider call data: ", provider);
      if (provider?.data?.length) {
        setProviderName(nameOfHospital);
        setSpectl(typeOfPractice);
        setAdd1(provider.data[0].address_line1);
        setAdd2(provider.data[0].address_line2);
        setCity(provider.data[0].city);
        setState(provider.data[0].state);
        setCountry(provider.data[0].country);
        setZip(provider.data[0].zip);
        setContactNumber(provider.data[0].contact_number);
        setcountryCode(provider.data[0].country_code)
        setRegisterNo(provider.data[0].regNo);
        setSmsBeforeApt(provider.data[0].send_sms_before_apt);
        setReminderBeforeApt(provider.data[0].send_reminder_before);
        setregisteredContactNumber(provider.data[0].registeredContactNumber);
      }
    } catch (err) {
      console.error("err", err);
      ToastError("Failed To Fetch My Details");
    }
    setLoading(false);
  };

  useEffect(() => {
    const region = process.env.REACT_APP_REGION;
    if (
      region === IndianRegionScreenStatus.Region &&
      screenStatus === "COMPLETE"
    ) {
      fetchIndianRegionDetails();
    }
  }, [screenStatus]);

  const saveStaffDetails = () => {
    console.log("savestaffdata");
    if (process.env.REACT_APP_REGION === "INDIA") {
      let data = {
        screenStatus: "",
        smsBeforeApt: "",
        reminderBeforeApt: "",
      };
      if (screenStatus === IndianRegionScreenStatus.MyDetail) {
        data.screenStatus = IndianRegionScreenStatus.StaffDetail;
      }
      data.smsBeforeApt = smsBeforeApt || "3";
      data.reminderBeforeApt = reminderBeforeApt || "Yes";
      let url = process.env.REACT_APP_BEATS_UPDATE_STAFF_APPOINTMENT;
      let config = {
        method: "post",
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
        data: data,
      };
      showLoadMask(true);
      axios(config)
        .then(function (response) {
          showLoadMask(false);
          ToastSuccess("Successfully Saved.");
          if (screenStatus !== "COMPLETE") {
            setProviderScreenStatus(IndianRegionScreenStatus.StaffDetail);
          }
        })
        .catch(function (error) {
          showLoadMask(false);
          ToastError("Failed To Save!");
          console.log(error);
        });
    }
  };

  const saveOtherRegionDetails = () => {
    console.log("saveOtherRegionDetails");
    const attData = attribs();
    const nameOfHospital = attribVal("custom:Organization");
    const npi = attribVal("custom:NPI");
    const typeOfPractice = attribVal("custom:TypeOfPractice");
    // if (currentPass === "") {
    //   ToastError("Current Password is required");
    //   return false;
    // }
    // if (newPassword === "") {
    //   ToastError("New Password is required");
    //   return false;
    // }
    // if (confirmPass === "") {
    //   ToastError("Confirm Password is required");
    //   return false;
    // }

    if (providerName === "") {
      ToastError("Name of Provider/Practice is required");
      return false;
    }

    // if (newPassword !== confirmPass) {
    //   ToastError("New Password and Confirm Password are not Matching");
    //   return false;
    // }

    let data = JSON.stringify({
      address1: add1,
      address2: add2,
      city: city,
      state: state,
      postalCode: zip,
      password: currentPass,
      newPassword: newPassword,
      Organization: providerName,
      contactNumber: contactNumber,
      registeredContactNumber: registeredContactNumber,
      NPI: parseInt(npi),
      TypeOfPractice: typeOfPractice,
      countryCode: countryCode,
    });
    // let data = JSON.stringify({
    //   password: currentPass,
    //   newPassword: newPassword,
    //   Organization: nameOfHospital,
    //   NPI: parseInt(npi),
    //   TypeOfPractice: typeOfPractice,
    // });
    console.log(data);
    let config = {
      method: "post",
      url: process.env.REACT_APP_BEATS_USER_UPDATE_API,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "is-update": "update",
      },
      data: data,
    };
    showLoadMask(true);
    axios(config)
      .then(function (response) {
        ////debugger;
        showLoadMask(false);
        ToastSuccess("Successfully Updated.");
      })
      .catch(function (error) {
        ////debugger;
        showLoadMask(false);
        ToastError("Update Failed!");
        console.log(error);
      });
  };
  const updateOtherRegionDetails = () => {
    console.log("updateOtherRegionDetails");
    const attData = attribs();
    const nameOfHospital = attribVal("custom:Organization");
    const npi = attribVal("custom:NPI");
    const typeOfPractice = attribVal("custom:TypeOfPractice");
    // if (currentPass === "") {
    //   ToastError("Current Password is required");
    //   return false;
    // }
    // if (newPassword === "") {
    //   ToastError("New Password is required");
    //   return false;
    // }
    // if (confirmPass === "") {
    //   ToastError("Confirm Password is required");
    //   return false;
    // }

    if (providerName === "") {
      ToastError("Name of Provider/Practice is required");
      return false;
    }

    // if (newPassword !== confirmPass) {
    //   ToastError("New Password and Confirm Password are not Matching");
    //   return false;
    // }

    let data = JSON.stringify({
      address1: add1,
      address2: add2,
      city: city,
      state: state,
      postalCode: zip,
      password: currentPass,
      //newPassword: currentPass,
      Organization: providerName,
      registeredContactNumber: registeredContactNumber,
      contactNumber: contactNumber,
      NPI: parseInt(npi),
      TypeOfPractice: typeOfPractice,
      countryCode: countryCode,
    });
    // let data = JSON.stringify({
    //   password: currentPass,
    //   newPassword: newPassword,
    //   Organization: nameOfHospital,
    //   NPI: parseInt(npi),
    //   TypeOfPractice: typeOfPractice,
    // });
    console.log(data);
    let config = {
      method: "post",
      url: process.env.REACT_APP_BEATS_USER_UPDATE_API,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "is-update": "update",
      },
      data: data,
    };
    showLoadMask(true);
    axios(config)
      .then(function (response) {
        ////debugger;
        showLoadMask(false);
        ToastSuccess("Successfully Updated.");
        setTabState("view");
      })
      .catch(function (error) {
        ////debugger;
        showLoadMask(false);
        ToastError("Update Failed!");
        console.log(error);
      });
  };
  const savePass = () => {
    console.log("savePass");
    if (process.env.REACT_APP_REGION === "INDIA") saveIndianRegionDetails();
    else saveOtherRegionDetails();
  };
  const updatePass = () => {
    console.log("updatePass");
    if (process.env.REACT_APP_REGION === "INDIA") updateIndianRegionDetails();
    else updateOtherRegionDetails();

  };

  const handleChange = (event, newValue) => {
    // alert(newValue);
    setValue(newValue);
  };

  const updateNpiData = (data) => {
    setNpiDetails(data);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onDrop = (event) => {
    let obj = event.target.files[0];
    obj["image"] = obj;
    obj["url"] = URL.createObjectURL(obj);
    setProfilePicture(obj);
  };

  const openFileUploader = () => {
    uploadInputRef.current.click();
  };

  const uploadProfile = async () => {
    let pictureObj = pictures;
    //const attributeData = attribs();
    let providerId = attribVal("sub");
    if (pictureObj["image"] === "") {
      ToastError("Please select image file to upload");
      return false;
    }
    var data = new FormData();
    let filename = pictureObj["image"].name;
    data.append("file", pictureObj["image"]);
    const result = await toBase64(pictureObj["image"]).catch((e) => Error(e));
    if (result instanceof Error) {
      console.log("Error: ", result.message);
      return;
    }

    let convertedFile = result.split(",")[1];
    data.append("file", convertedFile);
    let fileData = {
      fileData: convertedFile,
    };

    let config = {
      method: "post",
      url: process.env.REACT_APP_BEATS_PROFILE_UPLOAD,
      headers: {
        "Content-Type": "application/json",
        providerid: providerId,
        filename: filename,
        "type-of-file": pictureObj["image"].type,
        Authorization: "Bearer " + idToken,
      },
      data: fileData,
    };

    setLoading(true);
    axios(config)
      .then(function (response) {
        ////debugger;
        setLoading(false);
        ToastSuccess("Profile picture is updated successfully");
        let filename = pictures["image"].name;
        setProfilePicture({ ...pictures, image: "" });
        let newArray = attribFilter("url");
        let providerId = attribVal("sub");
        newArray.push({ name: "url", value: providerId + "/" + filename });
        localStorage.setItem("attributes", JSON.stringify(newArray));
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
        ToastError(error.response.data.error);
        setProfilePicture({ url: "", image: "" });
      });
  };

  const saveUserConfig = () => {
    const daysbeforeservice = isDateChecked ? 1 : 0;
    const dateservice = isOnDateServiceChecked ? 1 : 0;
    const noDays = daysbeforeservice ? noOfDays : 0;
    if (!daysbeforeservice) {
      setNumbOfDays(0);
    }
    let data = JSON.stringify({
      onDateService: dateservice,
      daysBeforeDateOfService: daysbeforeservice,
      daysbefore: noDays,
    });
    let config = {
      method: "post",
      url: process.env.REACT_APP_BEATS_UPDATE_PROVIDER_PROFILE,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
      data: data,
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        ToastSuccess("Configuration details saved successfully");
      })
      .catch(function (error) {
        setLoading(false);
        ToastError("Couldn't save configuration information");
        console.log(error);
      });
  };

  const saveNpiDetails = () => {
    if (npiDetails === "") {
      saveUserConfig();
      return;
    }

    let npidata = JSON.stringify({
      attr: npiDetails,
    });
    let config = {
      method: "post",
      url: process.env.REACT_APP_BEATS_SAVE_PAYER_API,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
      data: npidata,
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        setLoading(false);
        saveUserConfig();
      })
      .catch(function (error) {
        setLoading(false);
        ToastError("Couldn't save configuration information");
        console.log(error);
      });
  };

  const nameOfHospital = attribVal("custom:Organization");
  const npi = attribVal("custom:NPI");
  const email = attribVal("email");
  const contact = "";
  const typeOfPracticeVal =
    typeOfPractice === "H"
      ? "Institution/Organization"
      : typeOfPractice === "AT"
        ? "Individual"
        : "";
  const typeOfServiceVal = typeOfService ? typeOfService : "";

  const appointmentHourReminderOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
  ];

  const secondReminder = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 6) return phoneNumber;
    return `${phoneNumber.slice(0, 5)}-${phoneNumber.slice(5, 10)}`;
  }

  const [columns, setColumns] = useState([
    {
      title: "Title",
      field: "title",
      lookup: {
        "Dr.": "Dr.",
        "Prof.": "Prof.",
        "Mr.": "Mr.",
        "Ms.": "Ms.",
        "Mrs.": "Mrs.",
      },
    },
    // { title: 'Name of Clinician/Staff', field: 'name' },
    {
      title: "First Name",
      field: "firstName",
      editComponent: (props) => (
        <TextField
          type="text"
          variant="standard"
          error={!props.value ? true : props.value.length < 2} //this works
          helperText={
            !props.value
              ? "Required"
              : props.value.length < 2
                ? "Required"
                : " "
          }
          value={props.value ? props.value : ""}
          onChange={(e) => {
            if (nameError.validateInput) {
              setNameError({
                ...nameError,
                validateInput: false,
              });
            }
            props.onChange(e.target.value);
          }}
        />
      ),
    },
    {
      title: "Last Name",
      field: "lastName",
      editComponent: (props) => (
        <TextField
          type="text"
          variant="standard"
          error={!props.value ? true : props.value.length < 2}
          helperText={
            !props.value
              ? "Required"
              : props.value.length < 2
                ? "Required"
                : " "
          }
          value={props.value ? props.value : ""}
          onChange={(e) => {
            if (nameError.validateInput) {
              setNameError({
                ...nameError,
                validateInput: false,
              });
            }
            props.onChange(e.target.value);
          }}
        />
      ),
    },
    {
      title: "Gender",
      field: "gender",
      lookup: { M: "Male", F: "Female" },
    },

    {
      title: "License No./NPI",
      field: "mciNo",
      editComponent: (props) => (
        <TextField
          type="text"
          variant="standard"
          error={!props.value ? true : props.value.length < 2}
          helperText={
            !props.value
              ? "Required"
              : props.value.length < 2
                ? "Required"
                : " "
          }
          value={props.value ? props.value : ""}
          onChange={(e) => {
            if (nameError.validateInput) {
              setNameError({
                ...nameError,
                validateInput: false,
              });
            }
            props.onChange(e.target.value);
          }}
        />
      ),
    },
    {
      title: "Email",
      field: "email",
      editComponent: (props) => (
        <TextField
          type="text"
          variant="standard"
          error={
            !props.value
              ? true
              : !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
                props.value
              )
          }
          helperText={
            !props.value
              ? "Required"
              : !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
                props.value
              )
                ? "Invalid email"
                : " "
          }
          value={props.value ? props.value : ""}
          onChange={(e) => {
            if (nameError.validateInput) {
              setNameError({
                ...nameError,
                validateInput: false,
              });
            }
            props.onChange(e.target.value);
          }}
        />
      ),
    },
    {
      title: "Cell Number",
      field: "contactNumber",
      editComponent: (props) => (
        <TextField
          type="tel"
          variant="standard"
          error={!props.value ? true : !/^\D*(\d\D*){10}$/.test(props.value)}
          helperText={
            !props.value
              ? "Required"
              : !/^\D*(\d\D*){10}$/.test(props.value)
                ? "10 digits"
                : " "
          }
          value={props.value ?
            props.value : ""}
          onChange={(e) => {
            if (nameError.validateInput) {
              setNameError({
                ...nameError,
                validateInput: false,
              });
            }
            props.onChange(e.target.value.substring(0, 3) +
              "-" +
              e.target.value.substring(3, 6) +
              "-" +
              e.target.value.substring(6, 10));
          }}
        />
      ),
    },
    {
      title: "Specialty",
      field: "specialities",
      editComponent: (props) => (
        <TextField
          type="text"
          variant="standard"
          error={!props.value ? true : props.value.length < 3}
          helperText={
            !props.value
              ? "Required"
              : props.value.length < 3
                ? "Required"
                : " "
          }
          value={props.value ? props.value : ""}
          onChange={(e) => {
            if (nameError.validateInput) {
              setNameError({
                ...nameError,
                validateInput: false,
              });
            }
            props.onChange(e.target.value);
          }}
        />
      ),
    },
    {
      title: "Practicing Si",
      field: "practisingMedSince",
      editComponent: (props) => (
        <TextField
          type="text"
          variant="standard"
          error={!props.value ? true : !/^\D*(\d\D*){4}$/.test(props.value)}
          helperText={
            !props.value
              ? "Required"
              : !/^\D*(\d\D*){4}$/.test(props.value)
                ? "YYYY year"
                : " "
          }
          value={props.value ? props.value : ""}
          onChange={(e) => {
            if (nameError.validateInput) {
              setNameError({
                ...nameError,
                validateInput: false,
              });
            }
            props.onChange(e.target.value);
          }}
        />
      ),
    },
  ]);

  const [staffData, setStaffData] = useState([
    {
      title: "Dr.",
      firstName: "Mehmet",
      middleName: "Zoya",
      lastName: "Baran",
      gender: "male",
      mciNo: 11222929222,
      email: "BaranMehmet@gmail.com",
      contactNumber: "637-726-7222",
      practisingMedSince: 2012,
      specialities: "Dental",
    },
    {
      title: "Mr.",
      firstName: "Zerya",
      middleName: "",
      lastName: "Betul",
      gender: "female",
      mciNo: 11229992922,
      email: "ZeryaBetul@gmail.com",
      contactNumber: "757-552-6232",
      practisingMedSince: 1978,
      specialities: "Cardiology",
    },
  ]);

  const addStaffData = (staffData) => {
    let config = {
      method: "post",
      url: "https://4feobrcpq3.execute-api.us-east-2.amazonaws.com/Physician/addPhysician",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
      data: staffData,
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        console.log(response);
        ToastSuccess("Added Staff Member");
        setStaffData((oldData) => [...oldData, staffData]);
        setLoading(false);
        // saveUserConfig();
      })
      .catch(function (error) {
        setLoading(false);
        ToastError("Couldn't save configuration information");
        console.log(error);
      });
  };

  const updateStaffData = () => {
    let data = staffData[staffData.length - 1];

    let config = {
      method: "post",
      url: "https://4feobrcpq3.execute-api.us-east-2.amazonaws.com/Physician/updatePhysician",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
      data: data,
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        console.log(response);
        ToastSuccess("Added Staff Member");
        setLoading(false);
        // saveUserConfig();
      })
      .catch(function (error) {
        setLoading(false);
        ToastError("Couldn't save configuration information");
        console.log(error);
      });
  };

  const deleteStaffData = () => {
    let data = { physicianId: 1 };

    let config = {
      method: "post",
      url: "https://4feobrcpq3.execute-api.us-east-2.amazonaws.com/Physician/deletePhysician",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
      data: data,
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        console.log(response);
        ToastSuccess("Deleted staff member");
        setLoading(false);
        // saveUserConfig();
      })
      .catch(function (error) {
        setLoading(false);
        ToastError("Couldn't delete staff member");
        console.log(error);
      });
  };

  const fetchStaffData = async () => {
    let staffDataConfig = {
      method: "post",
      url: "https://4feobrcpq3.execute-api.us-east-2.amazonaws.com/Physician/getPhysicians",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
    };
    try {
      let data = await axios(staffDataConfig);
      if (data !== undefined) {
        setStaffData({ data });
        console.log(data);
        ToastSuccess("Fetch Success");
      }
    } catch (e) {
      console.log("failed to fetch  staff data");
    }
  };

  //for specialty ui drop down
  //const [specialtiesListV, setSpecialtiesListV] = React.useState([]);
  const [spectl, setSpectl] = React.useState("");

  const fetchSpecialtiesList = async () => {
    let specialtiesConfigure = {
      method: "get",
      url: process.env.REACT_APP_BEATS_GET_SPECIALTIES_API,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
    };
    try {
      let specialties = await axios(specialtiesConfigure);
      let specialtiesData = [];
      let specialtiesList = [];
      let tempSpecialtiesList = [];
      var listOFSpecialties = [];
      for (var i = 0; i < specialties.data.length; i++) {
        if (
          tempSpecialtiesList.indexOf(specialties.data[i].specialty_name) > -1
        ) {
          continue;
        } else {
          let specialtyObj = {
            value: specialties.data[i].specialty_name,
            label: specialties.data[i].specialty_name,
          };
          specialtiesList.push(specialtyObj);
          tempSpecialtiesList.push(specialties.data[i].specialty_name);
        }
        let obj = {
          value: specialties.data[i].specialty_name,
          label: specialties.data[i].specialty_name,

          visit_reason: specialties.data[i].visit_reason,
          specialty_name: specialties.data[i].specialty_name,
        };
        specialtiesData.push(obj);
        listOFSpecialties.push(specialties.data[i].specialty_name);
      }
      var sortArray = listOFSpecialties.sort();
      var sortedNewSpecialtiesData = [];
      sortArray.map((item) => {
        var objj = {
          value: item, label: item
        };
        sortedNewSpecialtiesData.push(objj);
      })
      setSpecialtiesListV(sortedNewSpecialtiesData);
      //console.log(specialtiesListV);
    } catch (error) {
      // setSpecialtiesListV([{ value: "Data Not load", label: "Error ! Data Not load 848" }]);
      console.log("my profile specialty data not load", error);
    }
  };
  const getSpeciality = specialtiesListV.map((val) => (
    <MenuItem value={val.value}> {val.label} </MenuItem>
  ));

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <ToastContainer />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <img src={logodb} className="dblogo" alt="Beats Logo" />
          {/*  <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton> */}
        </div>

        {jwttoken == null && <LeftsideMenu />}

        {/*    <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <main id="maindiv" className={classes.content}>
        <div>
          <Grid className="disblock">
            <div className="maintab">
              <AppBar position="static">
                {process.env.REACT_APP_REGION === "US" && (
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                  >
                    <Tab
                      label="Healthcare Provider Details"
                      {...a11yProps(0)}
                    />
                    <Tab label="Staff Details" {...a11yProps(1)} />
                    <Tab label="My Subscriptions" {...a11yProps(2)} />
                  </Tabs>
                )}
                {process.env.REACT_APP_REGION === "INDIA" && (
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                  >
                    <Tab label="My Profile" {...a11yProps(0)} />
                    <Tab label="Staff Details" {...a11yProps(1)} />
                    <Tab label="My Subscriptions" {...a11yProps(2)} />
                  </Tabs>
                )}
              </AppBar>
              <TabPanel value={value} index={0}>
                <Grid item xs={12} sm={12}>
                  <Paper className="pad20  bx-shadow dbbox">
                    <Box className="toprightuploadicon  bx-shadow">
                      <CloudUpload onClick={uploadProfile}></CloudUpload>
                    </Box>
                    <Box className="toprighticon  bx-shadow">
                      <input
                        accept="image/png, image/jpg,image/jpeg"
                        type="file"
                        ref={uploadInputRef}
                        style={{ display: "none" }}
                        onChange={(event) => {
                          onDrop(event);
                        }}
                      />
                      <EditIcon onClick={openFileUploader}></EditIcon>
                    </Box>{" "}
                    <p className="title1  ">My Profile  <CreateSharpIcon
                      style={{
                        display:
                          tabState !== "view"
                            ? "none"
                            : "inline-block", verticalAlign: "middle",
                      }}
                      onClick={() => setTabState("edit")}
                    ></CreateSharpIcon> </p>
                    <Grid container className="mydetailspage" spacing={3}>
                      <Grid item xs={12} sm={8} md={9}>
                        <Grid container className="detail-list" spacing={3}>


                          <Grid item xs={6}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} className="details-label">
                                {process.env.REACT_APP_REGION === "INDIA"
                                  ? "Name of the Hospital/Clinic/Lab"
                                  : "Name of the Provider / Practice"}

                              </Grid>

                              <Grid item xs={12} className="details-value">
                                <div
                                  style={{
                                    display:
                                      tabState !== "view" ? "none" : "block",
                                  }}
                                >
                                  {providerName}
                                </div>
                                <div
                                  style={{
                                    display:
                                      tabState !== "view" ? "block" : "none",
                                  }}
                                >
                                  <BootstrapInput
                                    style={{
                                      display:
                                        tabState !== "view" ? "block" : "none",
                                    }}
                                    className="primary-input mb20 width100p"
                                    placeholder=" "
                                    value={providerName}
                                    onChange={(e) => {
                                      setProviderName(e.target.value);
                                    }}

                                  ></BootstrapInput>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={6}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} className="details-label">
                                {process.env.REACT_APP_REGION == "INDIA"
                                  ? "License / Registration No"
                                  : "Type of Service"}
                              </Grid>

                              <Grid item xs={12} className="details-value">
                                <div
                                  style={{
                                    display:
                                      tabState !== "view" ? "none" : "block",
                                  }}
                                >
                                  {registerNo}
                                </div>
                                <div
                                  style={{
                                    display:
                                      tabState !== "view" ? "block" : "none",
                                  }}
                                >
                                  <BootstrapInput
                                    style={{
                                      display:
                                        tabState !== "view" ? "block" : "none",
                                    }}
                                    className="primary-input mb20 width100p"
                                    placeholder=" "
                                    value={registerNo}
                                    onChange={(e) => {
                                      setRegisterNo(e.target.value);
                                    }}

                                  ></BootstrapInput>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>


                          {/* <Grid item xs={12} md={6}>
                            <p className="bodycopyg1">
                              {" "}
                              {process.env.REACT_APP_REGION === "INDIA"
                                ? "Name of the Hospital/Clinic/Lab"
                                : "Name of the Provider / Practice"}
                            </p>
                             <BootstrapInput
                              className="primary-input width100p"
                               value={providerName}
                              onChange={(e) => {
                                setProviderName(e.target.value);
                              }}
                            />
                          </Grid> */}
                          {/* <Grid item xs={12} sm={6}>
                            <p className="bodycopyg1">
                              National Provider Identifier (NPI)
                            </p>
                            <p className="copytitle1">{npi}</p>
                          </Grid> */}
                          {/* <Grid item xs={12} md={6}>
                            <p className="bodycopyg1">
                              {process.env.REACT_APP_REGION == "INDIA"
                                ? "License / Registration No"
                                : "Type of Service"}
                            </p>
                            {process.env.REACT_APP_REGION === "INDIA" && (
                              <BootstrapInput
                                className="primary-input width100p"
                                
                                value={registerNo}
                                onChange={(e) => {
                                  setRegisterNo(e.target.value);
                                }}
                              />
                            )}
                            <p className="copytitle1">{typeOfServiceVal}</p>
                          </Grid> */}

                          <Grid item xs={12} md={6} style={{ paddingBottom: 0 }} className="mb0 pb0">
                            <p className="details-label">
                              {" "}
                              Registered email address
                            </p>
                            <p style={{
                              display:
                                tabState !== "view" ? "none" : "block",
                            }} className="details-value">{email}</p>

                            <p style={{
                              display:
                                tabState !== "view" ? "block" : "none", lineHeight: "48px",
                            }} className="details-value">{email}</p>

                          </Grid>
                          <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }} className="mb0 pb0">
                            <p className="bodycopyg1">
                              {" "}{/** Registered contact number */}
                              Contact Number
                            </p>
                            <p
                              style={{
                                display:
                                  tabState !== "view" ? "none" : "block",
                              }}
                              className="details-value">
                              {
                                registeredContactNumber ? registeredContactNumber : '' //contactNumber
                              }
                            </p>
                            {
                              process.env.REACT_APP_REGION === "INDIA" && (
                                <BootstrapInput
                                  maxLength={10}
                                  style={{
                                    display:
                                      tabState !== "view" ? "block" : "none",
                                  }}
                                  value={registeredContactNumberFormat !== '' ? registeredContactNumberFormat : registeredContactNumber}
                                  //type={inputType}
                                  className="primary-input mb20 width100p"
                                  //  placeholder="Name of the provider/practice"
                                  onChange={(e) => {
                                    var val = e.target.value;
                                    setregisteredContactNumber(val);
                                    setregisteredContactNumberFormatted('registeredContactNumber', val)
                                    /*if (val.length > 0) {
                                      const regExNumberOnly = /^\d{0,10}$/;
                                      setInputType("number");
                                      if (regExNumberOnly.test(val)) {
                                        if (val.length === 10) {
                                          var formated = val.substring(0, 3) + '-' + val.substring(3, 6) + '-' + val.substring(6, 10);
                                          setInputType("text");
                                          setregisteredContactNumber(formated);
                                        } else if (val.length < 10) {
                                          setInputType("number");
                                          setregisteredContactNumber(val);
                                        } else if (val.length > 10) {
                                          setInputType("number");
                                          setregisteredContactNumber(val);
                                        }
                                      } else {
                                        setInputType("number");
                                        setregisteredContactNumber(val);
                                      }
                                    }else{
                                      setregisteredContactNumber(val);
                                    }*/
                                  }}
                                />
                                /*<PhoneInput
                                  country={'us'}
                                  value={registeredContactNumber !== '' ? contactNumber : registeredContactNumber}
                                  onChange={(e) => {
                                    setregisteredContactNumber(e);
                                   // setContactNumber(e);
                                  }}
                                />*/
                              )
                            }
                          </Grid>



                          <Grid item xs={6}>
                            <Grid container spacing={1} style={{ marginBottom: "32px" }}>
                              <Grid item xs={12} className="details-label" >
                                {process.env.REACT_APP_REGION == "INDIA"
                                  ? "Specialty"
                                  : "Type of Practice"}
                              </Grid>

                              <Grid item xs={12} className="details-value">
                                <span
                                  style={{
                                    display:
                                      tabState !== "view" ? "none" : "block",
                                  }}
                                >
                                  {spectl}
                                </span>

                                <span style={{
                                  display:
                                    tabState !== "view" ? "block" : "none",
                                }}>
                                  <FormControl FormControl
                                    variant="outlined"
                                    label="outlined"
                                    style={
                                      {
                                        width: "100%",
                                        height: "50px",
                                        color: "red",
                                        margin: "0px",
                                      }
                                    } >

                                    <InputLabel id="appointment-from-time-label">Specialty</InputLabel>
                                    <MUIselect
                                      labelId="myprofile-speciality-label"
                                      id="myprofileSpecialty"
                                      value={spectl}
                                      onChange={(e) => {
                                        setSpectl(e.target.value);
                                      }}
                                      fullWidth
                                      label="Speciality"
                                    >
                                      {getSpeciality}
                                    </MUIselect>
                                  </FormControl>

                                </span>
                              </Grid>
                            </Grid>
                          </Grid>



                          {/*  <Grid item xs={12} md={6}>
                           
                            <FormControl
                              variant="outlined"
                              label="outlined"
                              style={{
                                width: "100%",
                                height: "50px",
                                color: "red",
                                margin: "0px",
                              }}
                            >
                              <InputLabel id="myprofile-speciality-label">
                                {process.env.REACT_APP_REGION == "INDIA"
                                  ? "Specialty"
                                  : "Type of Practice"}
                              </InputLabel>
                              { process.env.REACT_APP_REGION === "INDIA" && (
                              <MUIselect
                                labelId="myprofile-speciality-label"
                                id="myprofileSpecialty"
                                value={spectl}
                                onChange={(e) => {
                                  setSpectl(e.target.value);
                                }}
                                fullWidth
                                label="Speciality"
                              >
                                {getSpeciality}
                              </MUIselect>
                              )
                              }
                              { process.env.REACT_APP_REGION != "INDIA" && (
                            <p className="copytitle1">
                            {typeOfPracticeVal}
                            {""}
                          </p>
                              )
                              }
                              </FormControl>
                          </Grid> */}
                          {/*  <Grid item xs={12} sm={6}>
                            <p className="bodycopyg1"> 
                            {process.env.REACT_APP_REGION == 'INDIA' ? "License / Registration No" : "Type of Service"}
                            </p>
                            <p className="copytitle1">{typeOfServiceVal}</p>
                          </Grid> */}
                          {/* {process.env.REACT_APP_REGION == "INDIA" && (
                            <Grid item xs={12} md={6}>
                              <p></p>
                            </Grid>
                          )}*/}

                        </Grid>
                        <Divider className="dividercls" />
                        <Grid container className="detail-list" spacing={3}>



                          {process.env.REACT_APP_REGION == "US" && (
                            <Grid item xs={12} md={6}>
                              <p className="bodycopyg1"> Taxonomy Details</p>
                              <p className="copytitle2">Desc: {taxonomyDesc}</p>
                              <p className="copytitle2">Code: {taxonomyCode}</p>
                              <p className="copytitle2">
                                Group: {taxonomyGroup} { }
                              </p>
                              <p className="copytitle2">
                                Licence: {taxonomyLicense}
                              </p>
                              <p className="copytitle2">
                                State: {taxonomyState}
                              </p>
                            </Grid>
                          )}
                          <Grid item xs={12} md={12}>
                            <p className="bodycopyg1 redcolor"> <b> Physical address </b></p>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} className="details-label">
                                Address Line 1
                              </Grid>

                              <Grid item xs={12} className="details-value">
                                <div
                                  style={{
                                    display:
                                      tabState !== "view" ? "none" : "block",
                                  }}
                                >
                                  {add1}
                                </div>
                                <div
                                  style={{
                                    display:
                                      tabState !== "view" ? "block" : "none",
                                  }}
                                >
                                  <BootstrapInput
                                    style={{
                                      display:
                                        tabState !== "view" ? "block" : "none",
                                    }}
                                    className="primary-input mb20 width100p"
                                    placeholder=" "
                                    value={add1}
                                    onChange={(e) => {
                                      setAdd1(e.target.value);
                                    }}
                                  ></BootstrapInput>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={6}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} className="details-label">
                                Address Line 2
                              </Grid>

                              <Grid item xs={12} className="details-value">
                                <div
                                  style={{
                                    display:
                                      tabState !== "view" ? "none" : "block",
                                  }}
                                >
                                  {add2}
                                </div>
                                <div
                                  style={{
                                    display:
                                      tabState !== "view" ? "block" : "none",
                                  }}
                                >
                                  <BootstrapInput
                                    style={{
                                      display:
                                        tabState !== "view" ? "block" : "none",
                                    }}
                                    className="primary-input mb20 width100p"
                                    placeholder=" "
                                    value={add2}
                                    onChange={(e) => {
                                      setAdd2(e.target.value);
                                    }}
                                  ></BootstrapInput>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={6}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} className="details-label">
                                City
                              </Grid>

                              <Grid item xs={12} className="details-value">
                                <div
                                  style={{
                                    display:
                                      tabState !== "view" ? "none" : "block",
                                  }}
                                >
                                  {city}
                                </div>
                                <div
                                  style={{
                                    display:
                                      tabState !== "view" ? "block" : "none",
                                  }}
                                >
                                  <BootstrapInput
                                    style={{
                                      display:
                                        tabState !== "view" ? "block" : "none",
                                    }}
                                    className="primary-input mb20 width100p"
                                    placeholder=" "
                                    value={city}
                                    onChange={(e) => {
                                      setCity(e.target.value);
                                    }}
                                  ></BootstrapInput>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={6}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} className="details-label">
                                State
                              </Grid>

                              <Grid item xs={12} className="details-value">
                                <div
                                  style={{
                                    display:
                                      tabState !== "view" ? "none" : "block",
                                  }}
                                >
                                  {state}
                                </div>
                                <div
                                  style={{
                                    display:
                                      tabState !== "view" ? "block" : "none",
                                  }}
                                >
                                  <BootstrapInput
                                    style={{
                                      display:
                                        tabState !== "view" ? "block" : "none",
                                    }}
                                    className="primary-input mb20 width100p"
                                    placeholder=" "
                                    value={state}
                                    onChange={(e) => {
                                      setState(e.target.value);
                                    }}
                                  ></BootstrapInput>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={6}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} className="details-label">
                                Country
                              </Grid>

                              <Grid item xs={12} className="details-value">
                                <div
                                  style={{
                                    display:
                                      tabState !== "view" ? "none" : "block",
                                  }}
                                >
                                  {country}
                                </div>
                                <div
                                  style={{
                                    display:
                                      tabState !== "view" ? "block" : "none",
                                  }}
                                >
                                  <BootstrapInput
                                    style={{
                                      display:
                                        tabState !== "view" ? "block" : "none",
                                    }}
                                    className="primary-input mb20 width100p"
                                    placeholder=" "
                                    value={country}
                                    onChange={(e) => {
                                      setCountry(e.target.value);
                                    }}
                                  ></BootstrapInput>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={6}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} className="details-label">
                                {process.env.REACT_APP_REGION == "INDIA"
                                  ? "PIN Code"
                                  : "ZIP Code"}
                              </Grid>

                              <Grid item xs={12} className="details-value">
                                <div
                                  style={{
                                    display:
                                      tabState !== "view" ? "none" : "block",
                                  }}
                                >
                                  {zip}
                                </div>
                                <div
                                  style={{
                                    display:
                                      tabState !== "view" ? "block" : "none",
                                  }}
                                >
                                  <BootstrapInput
                                    style={{
                                      display:
                                        tabState !== "view" ? "block" : "none",
                                    }}
                                    className="primary-input mb20 width100p"
                                    placeholder=" "
                                    value={zip}
                                    onChange={(e) => {
                                      setZip(e.target.value);
                                    }}
                                  ></BootstrapInput>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={6}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} className="details-label">
                                Phone number
                              </Grid>

                              <Grid item xs={12} className="details-value">
                                <div
                                  style={{
                                    display:
                                      tabState !== "view" ? "none" : "block",
                                  }}
                                >
                                  {contactNumber ? ( '+' + countryCode + ' ' + contactNumber) : ""}
                                </div>
                                <div
                                  style={{
                                    display:
                                      tabState !== "view" ? "block" : "none",
                                  }}
                                >
                                  { /*<BootstrapInput
                                    style={{
                                      display:
                                        tabState !== "view" ? "block" : "none",
                                    }}
                                    className="primary-input mb20 width100p"
                                    placeholder=" "
                                    value={contactNumber ?
                                      contactNumber.length == 10 ?
                                        contactNumber.substring(0, 3) +
                                        "-" +
                                        contactNumber.substring(3, 6) +
                                        "-" +
                                        contactNumber.substring(6, 10) : contactNumber
                                      : ""
                                    }
                                    onChange={(e) => {

                                      setContactNumber(e.target.value)

                                    }}
                                  />
                                  */}
                                  <PhoneInput
                                    style={{
                                      display:
                                        tabState !== "view" ? "block" : "none",
                                    }}
                                    // country={countryCode}
                                    value={(countryCode + '' + contactNumber)}
                                    onChange={(e, code) => {
                                      var val = e.toString();
                                      var co = code.dialCode.toString();
                                      var rep = val.replace(co, '')
                                      setContactNumber(rep);
                                      // console.log(contactNumber);
                                      //console.log(e, '\n', code.dialCode, '\n', rep, '\n', contactNumber);
                                      setcountryCode(code.dialCode);

                                    }}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>


                          {/*   <Grid item xs={12} md={6}>

                            <BootstrapInput
                              className="primary-input  width100p"
                              placeholder="Address Line 1"
                              value={add1}
                              onChange={(e) => {
                                setAdd1(e.target.value);
                              }}
                            />
                          </Grid> */}



                          {/*    <Grid item xs={12} md={6} >
                            <BootstrapInput
                              className="primary-input  width100p"
                              placeholder="Address Line 2  (Optional)"
                              value={add2}
                              onChange={(e) => {
                                setAdd2(e.target.value);
                              }}
                            />
                          </Grid> */}

                          {/*  <Grid item xs={12} md={6} >
                            <BootstrapInput
                              className="primary-input  width100p"
                              placeholder="City"
                              value={city}
                              onChange={(e) => {
                                setCity(e.target.value);
                              }}
                            />
                          </Grid> */}

                          {/* <Grid item xs={12} md={6} >
                            <BootstrapInput
                              className="primary-input  width100p"
                              placeholder="State"
                              value={state}
                              onChange={(e) => {
                                setState(e.target.value);
                              }}
                            />
                          </Grid> */}
                          {/*  <Grid item xs={12} md={6} >
                            <BootstrapInput
                              className="primary-input  width100p"
                              placeholder="Country"
                              value={country}
                              onChange={(e) => {
                                setCountry(e.target.value);
                              }}
                            />
                          </Grid> */}
                          {/*  <Grid item xs={12} md={6} >
                            {process.env.REACT_APP_REGION === "US" && (
                              <BootstrapInput
                                className="primary-input  width100p"
                                placeholder="ZIP Code"
                                value={zip}
                                onChange={(e) => {
                                  setZip(e.target.value);
                                }}
                              />
                            )}
                            {process.env.REACT_APP_REGION === "INDIA" && (
                              <BootstrapInput
                                className="primary-input  width100p"
                                placeholder="PIN Code"
                                value={zip}
                                onChange={(e) => {
                                  setZip(e.target.value);
                                }}
                              />
                            )}
                          </Grid> */}
                          {/*  
                            <Grid item xs={12} md={6} >
                            <BootstrapInput
                              className="primary-input mb20 width100p"
                              placeholder="Phone number"
                              value={contactNumber ?
                                contactNumber.length == 10 ?
                                  contactNumber.substring(0, 3) +
                                  "-" +
                                  contactNumber.substring(3, 6) +
                                  "-" +
                                  contactNumber.substring(6, 10) : contactNumber
                                : ""
                              }
                              onChange={(e) => {

                                setContactNumber(e.target.value)

                              }}
                            />
                           
                            { /*<p className="copytitle2">{addressLine1}</p>
                            <p className="copytitle2">{addressLine2}</p>
                            <p className="copytitle2">
                              {cityName} {}
                            </p>
                            <p className="copytitle2">{stateName}</p>
                            <p className="copytitle2">{countryName}</p>
                            <p className="copytitle2">{zipCode}</p>  
                           */}

                          <Grid container className="signinbototm mt30" style={{ paddingLeft: 15 }}>
                            <Grid item xs={12} sm={9} md={9}
                              style={{
                                display:
                                  tabState !== "view" ? "block" : "none",
                              }}
                            >
                              <a href="#" className="btn-secondary mr30"
                                onClick={() => setTabState("view")}
                              >
                                Cancel
                              </a>
                              <button className="btn-primary" onClick={updatePass}>
                                Update
                              </button>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <hr className="redline" />
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <Grid container spacing={3}>
                              {jwttoken == null && (
                                <>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <p className="bodycopyg1">
                                      {" "}
                                      Current Password{" "}
                                    </p>
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <BootstrapInput
                                      className="primary-input"
                                      type="password"
                                      onChange={(e) =>
                                        setCurrentPass(e.target.value)
                                      }
                                    //id="bootstrap-input"
                                    />
                                  </Grid>
                                </>
                              )}
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6} md={6}>
                                <p className="bodycopyg1"> New Password </p>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <BootstrapInput
                                  className="primary-input "
                                  placeholder=""
                                  //id="bootstrap-input"
                                  type="password"
                                  onChange={(e) =>
                                    setNewPassword(e.target.value)
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6} md={6}>
                                <p className="bodycopyg1"> Confirm Password </p>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <BootstrapInput
                                  className="primary-input "
                                  placeholder=""
                                  id="bootstrap-input"
                                  type="password"
                                  onChange={(e) =>
                                    setConfirmPass(e.target.value)
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <Paper
                          className=" bx-shadow"
                          style={{ textAlign: "center" }}
                        >
                          <img
                            alt="Logo"
                            className="imgresponsive"
                            style={{
                              borderRadius: "50%",
                              height: "250px",
                              width: "250px",
                            }}
                            src={
                              pictures.url !== "" &&
                                pictures.url !== undefined &&
                                pictures.url !== null
                                ? pictures.url
                                : "assets/img/dental.jpg"
                            }
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid container className="signinbototm mt30 mrl0" >
                      <Grid item xs={12} sm={9} md={9}>
                        <button className="btn-primary" onClick={savePass}>
                          Save
                        </button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid item xs={12} sm={12}>
                  <Paper className="pad12  bx-shadow dbbox">
                    <Grid
                      container
                      spacing={6}
                      className=" pad12 staffdetails "
                    >
                      {/**privius grid */}
                      {/* 
                       <Grid item xs={20} sm={20} md={20} className=" pad20 staffdetails ">

                      <MaterialTable
                          title="Staff Details (to assign to patients)"
                          style={{ width: 1225 }}
                          columns={columns}
                          data={staffData}
                          icons={tableIcons}

                          options={{
                            sorting: true, search: true,
                            searchFieldAlignment: "right",
                            toolbarButtonAlignment: "left",
                            toolbarButtonStyle: {
                              backgroundColor: '#607d8b',
                              color: '#FFF',
                              ':hover': {
                                color: 'black',
                              },
                            },
                          }}

                          editable={{
                            onRowAdd: newData =>
                              new Promise((resolve, reject) => {
                                setTimeout(() => {
                                  // setStaffData([...staffData, newData]);
                                  addStaffData(newData);

                                  resolve();
                                }, 1000)
                              })
                            ,
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                setTimeout(() => {
                                  const dataUpdate = [...staffData];
                                  const index = oldData.tableData.id;
                                  dataUpdate[index] = newData;
                                  setStaffData([...dataUpdate]);

                                  resolve();
                                }, 1000)
                              }),
                            onRowDelete: oldData =>
                              new Promise((resolve, reject) => {
                                setTimeout(() => {
                                  const dataDelete = [...staffData];
                                  const index = oldData.tableData.id;
                                  dataDelete.splice(index, 1);
                                  setStaffData([...dataDelete]);
                                  deleteStaffData();

                                  resolve()
                                }, 1000)
                              }),
                          }}
                        />
                       </Grid>  */}

                      {/* <Grid item xs={4} sm={4} md={2}>
                            <p className="bodycopyg1 mb20 txt-center">
                              Title
                            </p>
                          </Grid>
                          <Grid item xs={4} sm={4} md={2}>
                            <p className="bodycopyg1 mb20 txt-center">
                              Name of Clinician/Staff
                            </p>
                          </Grid>
                          <Grid item xs={4} sm={4} md={2}>
                            <p className="bodycopyg1 mb20 txt-center">
                              Email ID
                            </p>
                          </Grid>
                          <Grid item xs={4} sm={4} md={2}>
                            <p className="bodycopyg1 mb20 txt-center">
                              Cell Number
                            </p>
                          </Grid>
                          <Grid item xs={4} sm={4} md={2}>
                            <p className="bodycopyg1 mb20 txt-center">
                              Specialty
                            </p>
                          </Grid> */}

                      {/* <Grid container spacing={6}> */}

                      {/* <Grid item xs={4} sm={4} md={2}>
                              <BootstrapInput
                                className="primary-input mb20 width100p"
                                placeholder=""
                                value={noOfDays}
                                style={{
                                  display: isDateChecked ? "block" : "none",
                                  marginLeft: 40,
                                  width: 50,
                                }}
                                onChange={(e) => {
                                  setNumbOfDays(
                                    isNaN(e.target.value) || e.target.value === ""
                                      ? 0
                                      : parseInt(e.target.value)
                                  );
                                }}
                              />
                          </Grid> */}

                      {/* </Grid> */}

                      {/*                         
                        <div>
                          {" "}
                          <Widget id={id} setNpiDetail={updateNpiData} loading={(val)=>setLoading(val)}/>{" "}
                        </div> */}
                      {/* </Grid> */}
                      <Grid item xs={12} sm={12}>
                        {/**<hr className="redline" /> */}
                        <StaffDetailsGride />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} className="pd0">
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: 20,
                            marginBottom: 10,
                          }}
                        >
                          {" "}
                          Notification Preference (for patients)
                        </p>
                      </Grid>
                      <Grid container spacing={3} style={{ marginBottom: 10 }}>
                        <Grid item xs={12} sm={12} md={3} className="pd0">
                          <p className="bodycopyg1" style={{ marginLeft: 25 }}>
                            On the day of appointment, how many hours before
                            appointment time{" "}
                          </p>
                          <p className="bodycopyg1" style={{ marginLeft: 25 }}>
                            {" "}
                            do you prefer to send SMS reminder to your patients?{" "}
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="pd0">
                          <Select
                            className="selectbox1"
                            //value={smsBeforeApt}
                            /*onChange={(e) => {
                              setSmsBeforeApt(e.target.value);
                              //console.log(`Option selected:`, e.value);
                            }}*/
                            onChange={(e) => handleSelectChange(e.value)}
                            // styles={customStyles}
                            options={appointmentHourReminderOptions}
                            //defaultValue={{ value: "3", label: "3" }}
                            value={appointmentHourReminderOptions.filter(function (option) {
                              return option.value === smsBeforeApt;
                            })}
                          />

                        </Grid>
                        <Grid item xs={12} sm={12} md={2} className="pd0">
                          <span style={{ paddingTop: "12px", display: "block" }}>Hours</span>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={3} className="pd0">
                          <p className="bodycopyg1" style={{ marginLeft: 25 }}>
                            In addition to sending SMS reminder on the date of
                            appointment,
                          </p>

                          <p className="bodycopyg1" style={{ marginLeft: 25 }}>
                            {" "}
                            do you prefer to send reminder 24hrs before
                            apppointment?{" "}
                          </p>
                          {/* <FormControlLabel
                            control={
                              <Checkbox
                                name="checkedB"
                                checked={isDateChecked}
                                onChange={(e) => {
                                  if (!isDateChecked === true) {
                                    setNumbOfDays(0);
                                  }
                                  setisDateChecked(!isDateChecked);
                                }}
                              />
                            }
                            label="Day(s) Before Date of Service"
                          /> */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className="pd0">
                          <Select
                            className="selectbox1"
                            //value={reminderBeforeApt}
                            onChange={(e) => handleReminderChange(e.value)}
                            //onChange={handleReminderChange}
                            // styles={customStyles}
                            options={secondReminder}
                            //defaultValue={{ value: "Yes", label: "Yes" }}
                            value={secondReminder.filter(function (option) {
                              return option.value === reminderBeforeApt;
                            })}
                          />
                          {/* <BootstrapInput
                            className="primary-input mb20 width100p"
                            placeholder=""
                            value={noOfDays}
                            style={{
                              display: isDateChecked ? "block" : "none",
                            }}
                            onChange={(e) => {
                              setNumbOfDays(
                                isNaN(e.target.value) || e.target.value === ""
                                  ? 0
                                  : parseInt(e.target.value)
                              );
                            }}
                          /> */}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <div className="mt2em">
                          <Button
                            variant="contained"
                            className="btn-primary"
                            // onClick={saveNpiDetails}
                            onClick={saveStaffDetails}
                          >
                            {screenStatus === IndianRegionScreenStatus.MyDetail
                              ? "Save & Continue"
                              : "Save"}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Grid item xs={12} sm={12}>
                  <Paper className="pad12  bx-shadow dbbox">
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        md={8}
                        className=" pad20 bx-shadow"
                      >
                        <p className="title1 mb20">My Subscriptions</p>

                        <FormControl className="txtform500">
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                              <Elements stripe={stripePromise}>
                                <StripeUpdate NPI={npi} />
                                {/* HomePage(); */}
                              </Elements>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        className="pad20 bx-shadow"
                      >
                        <Grid container className="orderconfirmation">
                          <Grid item xs={12}>
                            <Grid container className=" txt-left">
                              <Grid item xs={12} sm={12}>
                                <p className="bodycopy">
                                  <b>Basic Plan</b>
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container className=" txt-left">
                              <Grid item xs={7} sm={7}>
                                <p className="bodycopy">Subtotal Before Tax</p>
                              </Grid>
                              <Grid item xs={3} sm={3}>
                                <p className="bodycopy">&nbsp;&nbsp; INR 1,500 </p>
                              </Grid>
                              <Grid item xs={2} sm={2}>
                                <p className="bodycopy"> </p>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container className=" txt-left">
                              <Grid item xs={7} sm={7}>
                                <p className="bodycopy">Estimated Taxes and Fees</p>
                              </Grid>
                              <Grid item xs={5} sm={5}>
                                <p className="bodycopy">&nbsp;&nbsp; INR   315</p>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container className=" txt-left">
                              <Grid item xs={7} sm={7}>
                                <p className="bodycopy primary-copy">
                                  <b>Total (After taxes and fees)</b>
                                </p>
                              </Grid>
                              <Grid item xs={3} sm={3}>
                                <p className="bodycopy primary-copy">
                                  <b> &nbsp;&nbsp; INR 1,815 </b>
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <hr className="redline" />

                        <Grid container className="orderconfirmation">
                          <Grid item xs={12}>
                            <Grid container className=" txt-left">
                              <Grid item xs={12} className="mb20">
                                <p className="bodytitle ">
                                  Recurring Monthly Charge *
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; INR 1,500
                                </p>
                                <small>
                                  * Excludes taxes and transaction fees <br></br>
                                </small>
                                <small>
                                  ** Please note you will be charged after your 30 days free trial period  <br></br>
                                </small>
                                <small>
                                  *** For any questions or cancelling your subscription, please send an email to info@thebeatshealth.com from your registered email with subject “Clarification Required” or “Cancel my subscription"  <br></br>
                                </small>
                              </Grid>
                              <Grid item xs={7} sm={7}>
                                {/* <p className="bodycopy"> */}
                                {/* Renews on 30 April 2021 */}
                                {/* </p> */}
                              </Grid>
                              <Grid item xs={5} sm={5}>
                                {/* <p className="bodytitle txt-right"> $250 </p> */}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Grid item xs={12} sm={12}>
                  <Paper className="pad12  bx-shadow dbbox">
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className=" pad20 tabs-height "
                      >
                        <p className="title1 mb20">My Bills</p>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </TabPanel>
            </div>
          </Grid>
        </div>
      </main>
    </div>
  );
}
